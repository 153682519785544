import domain from "@/environment";
import axios from "axios";
const state = {
  systemeSolaire: [],
  chauffeBois:[],
  chaudièreBiomasse:[],
  devisMonoFiche: null,
  creationState: false,
  devisType:[],
};
const getters = {
  getdevisType: (state) => state.devisType,
  getcreationEntreprise: (state) => state.creationState,
  getPaiement:(state)=> state.paiement,
  getsystemeSolaire: (state) => state.systemeSolaire,
  getdevisMonoFiche: (state) => state.devisMonoFiche,
};
const mutations = {
  SET_DEVIS_TYPE_STATE(state, value) {
    state.devisType = value;
  },
  SET_CREATION_STATE(state, value) {
    state.creationState = value;
  },
  PUSH_SYSTEME_SOLAIRE(state, payload) {
    state.systemeSolaire.push(payload);
  },
  SET_DEVIS_MONO_FICHE(state, payload) {
    if (payload) {
      state.devisMonoFiche = payload;
    } else {
      state.devisMonoFiche = null; // Ou un objet vide { devis: [] }
    }
  },  
  UPDATE_SYSTEME_SOLAIRE(state, payload) {
    const index = state.systemeSolaire.findIndex(
      (sc) => sc.id === payload.id
    );
    if (index !== -1) {
      state.systemeSolaire[index] = {
        ...state.systemeSolaire[index],
        ...payload,
      };
    }
  },
  PUSH_chauffe_Bois(state, payload) {
    state.chauffeBois.push(payload);
  },
  UPDATE_chauffe_Bois(state, payload) {
    const index = state.chauffeBois.findIndex(
      (sc) => sc.id === payload.id
    );
    if (index !== -1) {
      state.chauffeBois[index] = {
        ...state.chauffeBois[index],
        ...payload,
      };
    }
  },
  PUSH_CHAUDIERE_BIOMASSE(state, payload) {
    state.chaudièreBiomasse.push(payload);
  },
  UPDATE_CHAUDIERE_BIOMASSE(state, payload) {
    const index = state.chaudièreBiomasse.findIndex(
      (sc) => sc.id === payload.id
    );
    if (index !== -1) {
      state.chaudièreBiomasse[index] = {
        ...state.chaudièreBiomasse[index],
        ...payload,
      };
    }
  },
};
const actions = {
  updateCreationEntreprise({ commit }, value) {
    commit('SET_CREATION_STATE', value);
  },
  devisTypeAdd({ commit }, value) {
    commit('SET_DEVIS_TYPE_STATE', value);
  },
  
  setDevisMonoFiche({ commit }, updatedDevisObject) {
    commit("SET_DEVIS_MONO_FICHE", updatedDevisObject);
  },
  async updatedevisMonoFiche(_,payload) {
    try {
  
      const responce = await axios.put(
        domain + `/devis/update/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      return responce;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        
        if (typeof errorMessage === "object") {
          const firstErrorKey = Object.keys(errorMessage)[0]; 
          return errorMessage[firstErrorKey][0]; 
        }
  
        return errorMessage; 
      }
  
      return "Une erreur inattendue s'est produite"; 
    }
  },
  async storeSystemeSolaire({commit},payload) {
      const response = await axios.post(
        domain + `/systeme_solaire_combinees`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("PUSH_SYSTEME_SOLAIRE", response.data.data);
      return response.data;
   
  },
  async editSystemeSolaire({commit},payload) {
    try {
      
      const response = await axios.put(
        domain + `/systeme_solaire_combinees/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      if (response && response.data && response.data.data) {
        commit("UPDATE_SYSTEME_SOLAIRE", response.data.data);
      } else {
       
        throw new Error("Unexpected response structure");
      }
      return response.data.data;
    } catch (error) {
      return error.response?.data?.message;
    }
  },
  async storechauffeBois({commit}, payload) {

      const response = await axios.post(
        domain + `/chauffage_au_bois`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("PUSH_chauffe_Bois", response.data.data);
      
      return response.data;
      
   
  },  
async editchauffeBois({commit},payload) {
  try {
    const response = await axios.put(
      domain + `/chauffage_au_bois/` + payload.id,
      payload,
      {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      }
    );
    if (response && response.data && response.data.data) {
      commit("UPDATE_chauffe_Bois", response.data.data);
    } else {
     
      throw new Error("Unexpected response structure");
    }
    return response.data.data;
  } catch (error) {
    return error.response?.data?.message;
  }
},
async storechaudiereBiomasse({commit}, payload) {

    const response = await axios.post(
      domain + `/chaudiere_biomasse`,
      payload,
      {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      }
    );
    
    commit("PUSH_CHAUDIERE_BIOMASSE", response.data.data);
    return response.data;
    
 
},  
async editchaudiereBiomasse({commit},payload) {
try {
  
  const response = await axios.put(
    domain + `/chaudiere_biomasse/` + payload.id,
    payload,
    {
      headers: {
        Authorization: `Bearer ` + this.state.token,
      },
    }
  );
  if (response && response.data && response.data.data) {
    commit("UPDATE_CHAUDIERE_BIOMASSE", response.data.data);
  } else {
   
    throw new Error("Unexpected response structure");
  }
  return response.data.data;
} catch (error) {
  return error.response?.data?.message;
}
},
async getoneMonodevis({ commit }, payload) {
  try {
    const response = await axios.get(domain + `/devis/${payload}`, {
      headers: {
        Authorization: `Bearer ` + this.state.token,
        Accept: 'application/json',
      },
    });
    commit("SET_DEVIS_MONO_FICHE", response.data.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data.message : error.message;
  }
}


};
export default { state, getters, actions,mutations };
